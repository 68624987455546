import { useFetch } from "@vgno/hooks";
import { classnames, fetcher } from "@vgno/utils";

import styles from "./CouponCodes.module.css";

type Data = {
  default: {
    header: string;
    image: string;
    image_alt: string;
    link: string;
    partner: string;
    subtext: string;
    text: string;
  }[];
};

const endpoint = "https://www.vg.no/rabattkode/rabagas/widget/1.0/feed.json";

export const CouponCodes = () => {
  const { data } = useFetch<Data>(endpoint, fetcher);

  const offers = data?.default;

  return (
    <section
      className={classnames(
        "layout-component",
        "layout-padded",
        styles.coupons,
      )}
      data-track-element-type="Coupon Codes"
      data-track-id="coupon-codes"
      data-track-impression
      data-track-name="Coupon Codes"
      data-track-primary-position={1}
      data-track-target-type="ExternalContent"
    >
      <h3 className="font-weight-semi-bold title-large">Rabattkoder</h3>
      <p className="label-medium">Et kommersielt samarbeid med Kickback.no</p>
      <ul className={styles.offers}>
        {offers?.map(
          (
            { header, image, image_alt, link, partner, subtext, text },
            index,
          ) => {
            return (
              <li key={link}>
                <a
                  className={styles.offer}
                  data-track-id={`coupon-code:${partner}`}
                  data-track-secondary-position={index + 1}
                  data-track-name={text}
                  href={`${link}?utm_source=vg&utm_medium=textlink&utm_campaign=widget`}
                  title={header}
                >
                  <img
                    alt={image_alt}
                    className={styles.image}
                    loading="lazy"
                    src={image}
                  />
                  <div>
                    <h4 className="font-weight-semi-bold title-small">
                      {header}
                    </h4>
                    <p className="label-medium my-xs">{text}</p>
                    <p className="label-medium color-accent">{subtext}</p>
                  </div>
                </a>
              </li>
            );
          },
        )}
      </ul>
    </section>
  );
};
